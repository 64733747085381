.react-tag-input {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    height: auto;
    min-height: 2.375em;
    padding: 3px 15px;
    /* padding: .1875em .375em; */
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 1rem;
    background: #add8e6;
    /*full background*/
    color: #000;
    border: none;
    border-radius: 30px;
}

.react-tag-input__tag {
    /* position: relative; */
    display: flex;
    align-items: center;
    font-size: .85em;
    line-height: 1;
    background: #fff;
    border-radius: 3px;
    border: .5px solid #0073db;
}

.react-tag-input__tag__remove {
    position: relative;
    height: 2em;
    width: 2em;
    font-size: .85em;
    cursor: pointer;
    background: #0073db;
    border-top-right-radius: 1px;
    border-bottom-right-radius: 1px;
}

.react-tag-input__tag__remove:before,
.react-tag-input__tag__remove:after {
    position: absolute;
    top: 50%;
    left: 50%;
    content: " ";
    height: 15px;
    width: .15em;
    background-color: #fff;
}

.react-tag-input__tag__content {
    outline: 0;
    border: none;
    white-space: nowrap;
    padding: 0px 10px;
    font-size: 15px;
}