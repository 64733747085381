.rec.rec-carousel-wrapper .rec-pagination .rec.rec-dot.rec {
    box-shadow: none;
    background: rgba(0, 0, 0, 0.1);
    display: none;
}

.iNRSST:hover:enabled,
.iNRSST:focus:enabled {
    color: #fff;
    background-color: #0073db;
    /* box-shadow: 0 0 2px 0 #333; */
}

.cdJtue {
    font-size: 30px !important;
    font-weight: 900 !important;
}

.kYuXMg {
    font-size: 30px !important;
    font-weight: 900 !important;
}

button.sc-iqHYmW.kYuXMg.rec.rec-arrow.rec.rec-arrow-right {
    font-size: 30px;
    font-weight: 900;
}

.kYuXMg:hover:enabled,
.kYuXMg:focus:enabled {
    color: #fff;
    background-color: #0073db !important;
    box-shadow: 0 0 2px 0 #333;
}