.tabs {
    cursor: pointer;
}

.active-tabs {
    /* background: rgba(24, 19, 19, 0.075); */
    color: #0073db;
}


/* .active-tabs::before {
  content: "";
  display: block;
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% + 2px);
  height: 5px;
  background: rgb(88, 147, 241);
} */

.content-tabs {
    flex-grow: 1;
}

.content {
    background: #fbfbfb;
    display: none;
}

.active-content {
    display: block;
}