.side-scrollbar::-webkit-scrollbar {
    width: 3px;
}

.side-scrollbar::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #707070;
    border-radius: 10px;
}

.side-scrollbar::-webkit-scrollbar-thumb {
    background: #0066c2;
    border-radius: 10px;
}