.ï»¿ .MuiToolbar-regular {
    display: none;
}

.MuiTable-root {
    border: 1px solid #ccc;
}

.MuiPaper-elevation2 {
    box-shadow: none !important;
}

.MTableToolbar-root-5 {
    display: none !important;
}

.MuiTableCell-root {
    padding: 10px;
    background-color: #fff;
    font-weight: 500 !important;
}