.jstabs {
    cursor: pointer;
    background-color: #d8d8d8;
}

.working-tabs {
    /* background: rgba(24, 19, 19, 0.075); */
    background-color: #0073db;
    color: #fff;
}

.content-tabs {
    flex-grow: 1;
}

.content {
    background: #fbfbfb;
    display: none;
}

.active-content {
    display: block;
}