a {
    text-decoration: none;
}

li {
    list-style: none;
}


/* navbar style start  */

.main-nav {
    width: 100%;
    height: 4rem;
    display: grid;
    grid-template-columns: 8rem 1fr 0.5fr 1fr 8rem;
}

.logo {
    display: grid;
    grid-column: 2/3;
    justify-content: start;
    align-items: center;
    margin-bottom: 15px;
}

@media (max-width: 768px) {
    .logo {
        margin-bottom: 0px;
    }
}

.menu-link {
    grid-column: 3/4;
}

.menu-link ul {
    height: 4rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0%;
}

.authmenu {
    grid-column: 4/5;
}

.authmenu ul {
    height: 4rem;
    display: grid;
    grid-template-columns: 3fr repeat(2, 1.5fr);
    align-items: center;
    justify-content: flex-end;
}

.authmenu ul li {
    text-align: right;
}

.authmenu ul li:first-child {
    grid-column: 2/3;
}


/* ----------- Grid part ends ----------------- */

.menu-link ul li a {
    text-transform: uppercase;
    color: rgba(0, 0, 3, 0.8);
    transition: 0.5s;
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
}

.menu-link ul li:hover>a {
    transform-origin: left;
    color: rgba(0, 0, 3, 1);
    text-decoration: underline;
    transition: 0.5s;
}

.authmenu ul li a {
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.55);
    transition: 0.5s;
    font-size: 14px;
    font-weight: 600;
    text-decoration: none !important;
}

.authmenu .hamburger-menu {
    display: none;
}

.signupmenu {
    display: none !important;
}


/* responsive css style  */

@media (max-width: 1080px) {
    .main-nav {
        height: 4rem;
        grid-template-columns: 2rem 2fr 2fr 2fr 2rem;
    }
    .logo,
    .menu-link ul,
    .authmenu ul {
        height: 4rem;
        grid-template-columns: 3fr repeat(2, 4fr);
    }
}


/* responsive css style  */

@media (max-width: 998px) {
    .main-nav {
        height: 5rem;
        grid-template-columns: 2rem 2fr 3fr 2rem 2rem;
    }
    .menu-link {
        display: none;
    }
    .logo,
    .authmenu ul {
        height: 5rem;
    }
    .mobile-menu-link {
        grid-column: 2/4;
        position: relative;
        z-index: 99;
    }
    .mobile-menu-link {
        background-color: white;
        height: auto;
        display: grid;
        grid-column: 2/5;
        align-items: center;
        /* padding-left: 3rem; */
        transition: all 2s linear;
        transform-origin: top;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    }
    .mobile-menu-link ul {
        height: auto;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        align-items: start;
        padding: 0;
        margin-bottom: 0;
    }
    .mobile-menu-link ul li {
        width: 100%;
        border-bottom: 1px solid #ccc;
        line-height: 2.5;
    }
    .mobile-menu-link ul li:first-child {
        transition-delay: 0.1s;
    }
    .menu-link ul li a {
        font-size: 14px;
        font-weight: 500;
        text-decoration: none;
        padding-left: 20px;
        color: rgba(0, 0, 0, 0.55);
    }
    .signupmenu {
        display: block !important;
    }
    .signupmenudesk {
        display: none !important;
    }
    .authmenu {
        grid-row: 1/2;
        grid-column: 3/5;
        justify-items: end;
        align-items: center;
        transition: all 2s linear;
    }
    .authmenu .authmenu-desktop {
        height: 0;
        display: none;
    }
    .authmenu {
        height: 5rem;
        display: flex;
        justify-self: end;
        align-items: center;
    }
    .authmenu .hamburger-menu {
        display: block;
        font-size: 2.5rem;
    }
}

@media (max-width: 798px) {
    .main-nav {
        height: 4rem;
        grid-template-columns: 0rem 2fr 1fr 1fr 0rem;
    }
    .logo,
    .authmenu ul {
        height: 4rem;
        padding-left: 20px;
    }
    .authmenu {
        height: 4rem;
        display: flex;
        justify-self: end;
        align-items: center;
    }
    .authmenu .hamburger-menu {
        display: block;
        font-size: 2rem;
        padding-right: 20px;
    }
}

@media (max-width: 520px) {
    .main-nav {
        height: 4rem;
        grid-template-columns: 0rem 4fr 1fr 1fr 0rem;
    }
    .logo,
    .authmenu ul {
        height: 4rem;
    }
    .logo h2 {
        font-size: 2rem;
    }
    .authmenu {
        height: 4yyrem;
        display: flex;
        justify-self: end;
        align-items: center;
    }
    .authmenu .hamburger-menu {
        display: block;
        font-size: 2rem;
    }
}